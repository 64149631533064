// import './App.css';
import '../../App.css'
import { useState, useEffect } from 'react';
import { Modal, Input, DatePicker, Table, Select, Pagination } from 'antd';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import qs from 'qs';
import dayjs from 'dayjs';
import axios from 'axios';
import Appconfig from '../../config/config'
import moment from 'moment';


function ProfitLossBetHistory() {
    const dateFormat = 'DD/MM/YYYY';
    const userInfo = JSON.parse(localStorage.getItem('userdata'))

    const location = useLocation();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(true)
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [data, setData] = useState([]);
    const params = useParams();
    // console.log('params', params);
    const [type, setType] = useState('unsettle')

    const [filteredData, setFilteredData] = useState(data);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        "user_id": userInfo && userInfo?._id,
        "match_id": params && params?.match_id,
        "market_id": params && params?.market_id,
        "from_date": params && params?.from_date,
        "to_date": params && params?.to_date,
    })


    let locale = {
        emptyText: 'No data!',
    };
    const columns = [
        {
            title: 'Sport Name',
            sorter: true,

            render: (_, record) => (
                record.event_type == 4 ?
                    (
                        <div className='font-extrabold text-[#212529] text-[0.813rem] '>
                            Cricket
                        </div>
                    ) : record.event_type == 2 ? (
                        <div className='font-extrabold text-[#212529] text-[0.813rem] '>
                            Tennis
                        </div>
                    ) : record.event_type == 1 ? (
                        <div className='font-extrabold text-[#212529] text-[0.813rem] '>
                            Soccer
                        </div>
                    ) : record.event_type == 7 ? (
                        <div className='font-extrabold text-[#212529] text-[0.813rem] '>
                            Horse Racing
                        </div>
                    ) : record.event_type == 1004 || record.event_type == 1005 || record.event_type == 1007 || record.event_type == 1008 || record.event_type == 1009 ? (
                        <div className='font-extrabold text-[#212529] text-[0.813rem] '>
                            Casino
                        </div>
                    ) : record.event_type == 4339 ? (
                        <div className='font-extrabold text-[#212529] text-[0.813rem] '>
                            Greyhound Racing
                        </div>
                    ) : record.event_type == 27979456 ? (
                        <div className='font-extrabold text-[#212529] text-[0.813rem] '>
                            Kabaddi
                        </div>
                    ) : record.event_type == 7522 ? (
                        <div className='font-extrabold text-[#212529] text-[0.813rem] '>
                            Basketball
                        </div>
                    ) : record.event_type == 2378961 ? (
                        <div className='font-extrabold text-[#212529] text-[0.813rem] '>
                            Politics
                        </div>
                    ) : record.event_type == 66101 ? (
                        <div className='font-extrabold text-[#212529] text-[0.813rem] '>
                            Virtual
                        </div>
                    ) : record.event_type == 66104 ? (
                        <div className='font-extrabold text-[#212529] text-[0.813rem] '>
                            Lottery
                        </div>
                    ) : (
                        <div className='font-extrabold text-[#212529] text-[0.813rem] '>
                            Other
                        </div>
                    )
            ),
        }, {
            title: 'Event Name',
            sorter: true,

            render: (_, record) => (
                <div className='font-extrabold text-[#212529] text-[0.813rem]' >
                    {record.event_name}
                </div>
            ),
        },
        {
            title: 'Market Name',
            sorter: true,

            render: (_, record) => (

                <div className='font-extrabold text-[#212529] text-[0.813rem]'>
                    {record.market_name}
                </div>

            ),
        },
        {
            title: 'Selection Name',
            sorter: true,

            render: (_, record) => (
                <div className='font-extrabold text-[#212529] text-[0.813rem]'>
                    {record.place_name}
                </div>
            ),
        },
        {
            title: 'Bet Type',
            sorter: true,

            render: (_, record) => (
                record.is_back ? (
                    <div className='font-extrabold text-[#212529] text-[0.813rem]'>
                        BACK
                    </div>
                ) : (
                    <div className='font-extrabold text-[#212529] text-[0.813rem]'>
                        Lay
                    </div>
                )

            ),
        },
        {
            title: 'User Price',
            sorter: true,

            render: (_, record) => (
                <div className='font-extrabold text-[#212529] text-[0.813rem]'>
                    {record.price_val}
                </div>
            ),
        },
        {
            title: 'Amount',
            sorter: true,

            render: (_, record) => (
                <div className='font-extrabold text-[#212529] text-[0.813rem]'>
                    {record.stake}
                </div>
            ),
        },

        {
            title: 'Profit/Loss',
            dataIndex: 'name',
            sorter: true,
            render: (_, record) => (
                record.totalPL < 0 ? (
                    <span className='font-extrabold text-[#ff0000] text-[0.813rem] '>
                        {record?.totalPL?.toFixed(2)}
                    </span>
                ) : (
                    <span className='font-extrabold text-[#008000] text-[0.813rem] '>{
                        record?.totalPL?.toFixed(2)
                    }</span>
                )
            )
        },

        {
            title: 'Place Date',
            sorter: true,

            render: (_, record) => (
                <div className='font-extrabold text-[#212529] text-[0.813rem]'>
                    {new Date(record.createdAt).toLocaleString()}
                </div>
            ),
        },
        {
            title: 'Match Date',
            sorter: true,

            render: (_, record) => (
                <div className='font-extrabold text-[#212529] text-[0.813rem]'>
                    {new Date(record.createdAt).toLocaleString()}
                </div>
            ),
        },
    ];

    const handlePageSizeChange = (e) => {
        setPageSize(e);
        setCurrent(1); // Reset to the first page after changing page size
    };
    const handleFirst = () => setCurrent(1);
    const handleLast = () => setCurrent(Math.ceil(filteredData?.length / pageSize));

    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    const changeSelectOptionHandler = (event) => {
        setType(event.target.value);
    };

    const getRandomuserParams = (params) => ({
        results: params.pagination?.pageSize,
        page: params.pagination?.current,
        ...params,
    });

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });


    const rowStyle = (record) => {
        return {
            style: {
                backgroundColor: record.is_back ? '#72bbef' : '#faa9ba',
            },
        };
    };


    useEffect(() => {
        filterProfitLossBetHistory();
    }, [])


    const filterProfitLossBetHistory = async (e) => {

        var data = JSON.stringify(formData);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/auraProfitLossBetHistory`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    console.log(response.data.resultData);
                    setFilteredData(response.data.resultData);

                } catch (e) {
                    // postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                // postErrorToslack(error.message);
            });
    }


    return (
        <>
            {/* Desktop */}
            <div className='hidden lg:block mt-[6px] !mb-4 text-[0.75rem] relative flex flex-col min-w-[0] [word-wrap:break-word]   rounded-[.25rem]'>

                <div className='col-span-12'>
                    <div className=' flex-[0_0_auto] w-full max-w-full'>
                        <div className='border-r-[1px] border-r-[#c8ced3] border-l-[1px] border-l-[#c8ced3] mb-[24px] border-t-[2px] border-t-[black] relative flex flex-col min-w-[0] text-[#212529] [word-wrap:break-word] bg-[#fff] bg-clip-border border-[1px] border-[solid] border-[#0000002d] rounded-[.375rem]'>
                            <div className='pt-[5px] px-[6px] pb-[6px] font-bold text-[14px] !bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] mb-0 border-b-[1px_solid_#0000002d] !text-[#ffffff] [word-wrap:break-word]'>
                                <span className='relative top-px font-bold text-[0.875rem] !text-[#ffffff]'>Bet History</span>
                            </div>

                            <div className='flex gap-[5px] items-center justify-end w-full p-[10px]'>
                                <div className='bg-[#72bbef] p-[8px] border-[1px] border-[#000000]'>
                                    Back
                                </div>
                                <div className='bg-[#faa9ba] p-[8px] border-[1px] border-[#000000]'>
                                    Lay
                                </div>
                                <div className='bg-[#ffffff] p-[8px] border-[1px] border-[#000000]'>
                                    Void
                                </div>
                            </div>

                            <div className='p-[1.25rem] flex-auto '>
                                <Table
                                    // title={() => (
                                    //     <div className='grid grid-cols-12'>
                                    //         <div className='col-span-12 lg:col-span-6 flex items-center justify-center lg:justify-start mb-[.9rem] lg:mb-[0px]'>
                                    //             <div className='text-[#333] lg:m-[.5rem]'>
                                    //                 <label>
                                    //                     Show
                                    //                     <Select defaultValue={10} onChange={handlePageSizeChange} className='pagesize-select mx-[5px] w-[80px]'>
                                    //                         <Select.Option value={10}>10</Select.Option>
                                    //                         <Select.Option value={25}>25</Select.Option>
                                    //                         <Select.Option value={50}>50</Select.Option>
                                    //                         <Select.Option value={100}>100</Select.Option>
                                    //                     </Select>
                                    //                     entries
                                    //                 </label>
                                    //             </div>
                                    //         </div>
                                    //         <div className='col-span-12 lg:col-span-6 flex items-center justify-center lg:justify-end lg:pt-[0px]'>
                                    //             <div className='text-[#333] lg:m-[.5rem]'>
                                    //                 <label>
                                    //                     Search:
                                    //                     <Input
                                    //                         // onChange={e => handleSearch(e.target.value, 'name')}
                                    //                         style={{ width: 155, marginLeft: '5px' }}
                                    //                     />
                                    //                 </label>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // )}
                                    locale={locale}
                                    columns={columns}
                                    className='overflow-auto profit-loss'
                                    bordered
                                    dataSource={paginatedData}
                                    onRow={(record) => rowStyle(record)}
                                    pagination={false} // Disable default pagination
                                />
                                <div className='grid grid-cols-12 items-center pt-[1.5rem] lg:pt-[.75rem] text-[#333]'>
                                    <div className='col-span-12 lg:col-span-6 text-[#333] text-[0.813rem]'>
                                        Showing {startIndex + 1} to {endIndex} of {filteredData?.length} entries
                                    </div>
                                    <div className='col-span-12 lg:col-span-6 flex items-center justify-end  pt-[1rem] lg:pt-[0px] lg:mt-[0rem]'>
                                        <button className='text-[0.813rem] cursor-default text-[#666] border-[1px] border-[solid] border-[transparent] bg-transparent [box-shadow:none] box-border inline-block min-w-[1.5em] px-[1em] py-[.5em] ml-[2px] text-center no-underline rounded-[2px] cursor-pointer' type='button' onClick={handleFirst} style={{ marginRight: 8 }}>First</button>
                                        <Pagination
                                            current={current}
                                            pageSize={pageSize}
                                            total={filteredData?.length}
                                            onChange={(page) => setCurrent(page)}
                                            itemRender={(page, type, originalElement) => {
                                                if (type === 'prev') {
                                                    return <button type='button' className='!cursor-pointer'>Previous</button>;
                                                }
                                                if (type === 'page') {
                                                    return null; // Hide page numbers
                                                }
                                                if (type === 'next') {
                                                    return <button type='button' className='!cursor-pointer'>Next</button>;
                                                }
                                                return originalElement;
                                            }}
                                            showSizeChanger={false} // Disable default page size changer
                                        />
                                        <button className='text-[0.813rem] cursor-default text-[#666] border-[1px] border-[solid] border-[transparent] bg-transparent [box-shadow:none] box-border inline-block min-w-[1.5em] px-[1em] py-[.5em] ml-[2px] text-center no-underline rounded-[2px]  cursor-pointer' type='button' onClick={handleLast} style={{ marginLeft: 8 }}>Last</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Mobile */}
            < div className='lg:hidden relative top-[56px]  text-[0.75rem]  flex flex-col min-w-[0] [word-wrap:break-word] rounded-[.25rem]' >
                <div className='grid grid-cols-12 mt-[22.5px] mb-[40px]'>

                    <div className='col-span-12'>
                        <div className=' flex-[0_0_auto] w-full max-w-full'>
                            <div className='border-r-[1px] border-r-[#c8ced3] border-l-[1px] border-l-[#c8ced3] mb-[24px] border-t-[2px] border-t-[black] relative flex flex-col min-w-[0] text-[#212529] [word-wrap:break-word] bg-[#fff] bg-clip-border border-[1px] border-[solid] border-[#0000002d] rounded-[.375rem]'>
                                <div className='pt-[5px] px-[6px] pb-[6px] font-bold text-[14px] !bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] mb-0 border-b-[1px_solid_#0000002d] !text-[#ffffff] [word-wrap:break-word]'>
                                    <span className='relative top-px font-bold text-[0.875rem] !text-[#ffffff]'>Bet History</span>
                                </div>
                                <div className='flex gap-[5px] items-center justify-end w-full p-[10px]'>
                                    <div className='bg-[#72bbef] p-[8px] border-[1px] border-[#000000]'>
                                        Back
                                    </div>
                                    <div className='bg-[#faa9ba] p-[8px] border-[1px] border-[#000000]'>
                                        Lay
                                    </div>
                                    <div className='bg-[#ffffff] p-[8px] border-[1px] border-[#000000]'>
                                        Void
                                    </div>
                                </div>
                                <div className='p-[.5rem] flex-auto '>
                                    <Table
                                        // title={() => (
                                        //     <div className='grid grid-cols-12'>
                                        //         <div className='col-span-12 lg:col-span-6 flex items-center justify-center lg:justify-start  lg:mb-[0px]'>
                                        //             <div className='text-[#333] lg:m-[.5rem]'>
                                        //                 <label>
                                        //                     Show
                                        //                     <Select defaultValue={10} onChange={handlePageSizeChange} className='pagesize-select mx-[10px] w-[80px]'>
                                        //                         <Select.Option value={10}>10</Select.Option>
                                        //                         <Select.Option value={25}>25</Select.Option>
                                        //                         <Select.Option value={50}>50</Select.Option>
                                        //                         <Select.Option value={100}>100</Select.Option>
                                        //                     </Select>
                                        //                     entries
                                        //                 </label>
                                        //             </div>
                                        //         </div>
                                        //         <div className='col-span-12 lg:col-span-6 flex items-center justify-center lg:justify-end  pt-[10px]'>
                                        //             <div className='text-[#333] lg:m-[.5rem]'>
                                        //                 <label>
                                        //                     Search:
                                        //                     <Input
                                        //                         // onChange={e => handleSearch(e.target.value, 'name')}
                                        //                         style={{ width: 155, marginLeft: '5px' }}
                                        //                     />
                                        //                 </label>
                                        //             </div>
                                        //         </div>
                                        //     </div>
                                        // )}
                                        locale={locale}
                                        columns={columns}
                                        className='overflow-auto bets-history'
                                        bordered
                                        dataSource={paginatedData}
                                        onRow={(record) => rowStyle(record)}
                                        pagination={false} // Disable default pagination
                                    />
                                    <div className='grid grid-cols-12 items-center pt-[.5rem] lg:pt-[.75rem] text-[#333]'>
                                        <div className='col-span-12 text-center lg:col-span-6 text-[#333] text-[0.813rem]'>
                                            Showing {startIndex + 1} to {endIndex} of {filteredData?.length} entries
                                        </div>
                                        <div className='col-span-12 lg:col-span-6 flex items-center justify-center  pt-[1rem] lg:pt-[0px] lg:mt-[0rem]'>
                                            <button className='text-[0.813rem] cursor-default text-[#666] border-[1px] border-[solid] border-[transparent] bg-transparent [box-shadow:none] box-border inline-block min-w-[1.5em] px-[1em] py-[.5em] ml-[2px] text-center no-underline rounded-[2px] cursor-pointer' type='button' onClick={handleFirst} style={{ marginRight: 8 }}>First</button>
                                            <Pagination
                                                current={current}
                                                pageSize={pageSize}
                                                total={filteredData?.length}
                                                onChange={(page) => setCurrent(page)}
                                                itemRender={(page, type, originalElement) => {
                                                    if (type === 'prev') {
                                                        return <button type='button' className='!cursor-pointer'>Previous</button>;
                                                    }
                                                    if (type === 'page') {
                                                        return null; // Hide page numbers
                                                    }
                                                    if (type === 'next') {
                                                        return <button type='button' className='!cursor-pointer'>Next</button>;
                                                    }
                                                    return originalElement;
                                                }}
                                                showSizeChanger={false} // Disable default page size changer
                                            />
                                            <button className='text-[0.813rem] cursor-default text-[#666] border-[1px] border-[solid] border-[transparent] bg-transparent [box-shadow:none] box-border inline-block min-w-[1.5em] px-[1em] py-[.5em] ml-[2px] text-center no-underline rounded-[2px]  cursor-pointer' type='button' onClick={handleLast} style={{ marginLeft: 8 }}>Last</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}


export default ProfitLossBetHistory;
