import '.././App.css'
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
// import Appconfig from '../../config/config'
import Appconfig from '../config/config'
import { effect, signal } from '@preact/signals-react';

export const allEventsSignal = signal('');
export const balanceSignal = signal(0);
export const exposureSignal = signal(0);
export const loadBalanceSignal = signal(true);
const userInfo = JSON.parse(localStorage.getItem('userdata'))
export const getEvents = async () => {
    const data = JSON.stringify({
        user_id: userInfo ? userInfo._id : "",
        event_type_id: 0,
    });
    let url = "get-dashboard-data-with-user-id";
    if (!userInfo) {
        url = "get-dashboard-data-without-user-id";
    }
    const config = {
        method: "post",
        url: `${Appconfig.apiUrl}eventsDashboard/${url}`,
        headers: {
            "Content-Type": "application/json",
        },
        data: data,
    };

    try {
        const response = await axios(config);
        if (response.data.result == 1) {
            // console.log('getEvents:', response.data?.resultData[0]?.competitions[0].events)
            if (response.data?.resultData[0]?.competitions[0].events.length > 0) {
                allEventsSignal.value = response.data?.resultData[0]?.competitions[0].events;
            }
        }
    } catch (error) {
        console.log(error);
    }
};

export const getBalance = async (userInfo) => {
    const data = JSON.stringify({
        user_id: userInfo?._id,
    });

    const config = {
        method: "post",
        url: `${Appconfig.apiUrl}ledger/getUserBalance`,
        headers: {
            "Content-Type": "application/json",
        },
        data: data,
    };

    try {
        const response = await axios(config);
        if (response.data.result != 0) {
            balanceSignal.value = response.data.resultData?.balance;
            exposureSignal.value = response.data.resultData?.exposure;
            loadBalanceSignal.value = false;
        }
    } catch (error) {
        console.log(error);
    }
};