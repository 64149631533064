import '../../App.css'
import { useState } from 'react';
import { LuClock9 } from "react-icons/lu";
import { SlScreenDesktop } from "react-icons/sl";
import { Link, useParams } from 'react-router-dom';
import { signal } from "@preact/signals-react";
export const eventId = signal("");
function Lottery() {
    const { id } = useParams();
    // eventId.value = id;
    const casino = [
        {
            'title': '20-20 TEENPATTI',
            'path': 'https://aura444.com/api/users/images/1%20Day%20Teen%20Patti-01%204.svg',
            'category': 'teen-patti'

        },
        {
            'title': 'LUCKY 7 - A',
            'path': '/LUCKY7-A.png',
            'category': 'lucky-7'
        },
        {
            'title': '20-20 DRAGON TIGER',
            'path': '/DragonTiger.png',
            'category': 'dragon-tiger'

        },
        {
            'title': 'BACCARAT',
            'path': '/Baccarat-min.png',
            'category': 'baccarat'

        },
        {
            'title': 'ANDAR BAHAR - A',
            'path': '/AndarBahar2-min-min.png',
            'category': 'andar-bahar'

        },
        {
            'title': '32 CARDS - A',
            'path': '/32Cards .png',
            'category': 'other'

        },
        {
            'title': '20-20 POKER -  A',
            'path': '/poker-min (1).png',
            'category': 'poker'

        },
        {
            'title': '1DAY TEEN PATTI',
            'path': '/I Day TeenPatti-min.png',
            'category': 'teen-patti'
        },
        {
            'title': 'DRAGON TIGER',
            'path': '/Dragon-Tiger-min.png',
            'category': 'dragon-tiger'

        },
        {
            'title': 'FAST LUCKY - 7',
            'path': '/Fast lucky7-min.png',
            'category': 'lucky-7'

        },
        {
            'title': '1 DAY DRAGON TIGER',
            'path': '/1 Day Dragon-Tiger-min.png',
            'category': 'dragon-tiger'

        },
        {
            'title': '29 CARD BACCARAT',
            'path': '/29 Baccarat-min.png',
            'category': 'baccarat'

        },
        {
            'title': 'MUFLIS TEEN PATTI',
            'path': '/MUFLISTEENPATTI.png',
            'category': 'teen-patti'
        },
        {
            'title': 'DTL - A',
            'path': '/DTL-01 3-min.png',
            'category': 'dragon-tiger'

        },
        {
            'title': 'FAST DRAGON TIGER',
            'path': '/FAST DRAGON TIGER.png',
            'category': 'dragon-tiger'
        },
        {
            'title': '3 CARD JUDGEMENT - A',
            'path': '/3 CARD JUDGEMENT - A.png',
            'category': 'other'

        },
        {
            'title': 'AMAR AKBAR ANTHONY',
            'path': '/AMAR AKBAR ANTHONY.png',
            'category': 'bollywood'

        },
        {
            'title': 'DTL TEENPATTI',
            'path': '/DTL TEENPATTI.png',
            'category': 'teen-patti'
        },
        {
            'title': 'INA MINA DIKA',
            'path': '/INA MINA DIKA.png',
            'category': 'bollywood'

        },
        {
            'title': 'INSTANT WORLI',
            'path': '/INSTANT WORLI.png',
            'category': 'other'

        },
        {
            'title': 'BOLLYWOOD CASINO',
            'path': '/BOLLYWOOD CASINO.png',
            'category': 'bollywood'

        },
        {
            'title': 'ANDAR BAHAR - C',
            'path': '/ANDAR BAHAR - C.png',
            'category': 'andar-bahar'

        },
        {
            'title': 'EZUGI',
            'path': '/EZUGI.png',
            'category': 'poker'

        },
        {
            'title': 'BETGAMES CASINO',
            'path': '/BETGAMES CASINO.png',
            'category': 'poker'

        },
        {
            'title': 'TVBET',
            'path': '/TVBET.png',
            'category': 'poker'

        },
        {
            'title': 'EVOLUTION',
            'path': '/EVOLUTION.png',
            'category': 'poker'

        },
        {
            'title': 'LUCKY 7 - B',
            'path': '/LUCKY 7 - B.png',
            'category': 'lucky-7'

        },
        {
            'title': '20-20 CARD RACE',
            'path': '/20-20 CARD RACE.png',
            'category': 'other'

        },
        {
            'title': 'BACCARAT - C',
            'path': '/BACCARAT - C.png',
            'category': 'baccarat'

        },
        {
            'title': '1 CARD METER',
            'path': '/1 CARD METER.png',
            'category': 'other'

        },
        {
            'title': 'TRIO',
            'path': '/TRIO.png',
            'category': 'other'
        },

    ]

    return (
        <>
            {/* Desktop */}

            <div className="hidden lg:grid grid-cols-12 gap-x-6 bg-[url('/public/casinobg.png')] bg-contain bg-fixed p-[0.938rem] ">
                <div className='col-span-3'>
                    <div className='relative h-[16vh]'>
                        <div className='clip'></div>
                        <div className='clip2'>
                            <img title="" alt="" className="w-full h-full" src='/lottery.png' />
                        </div>
                    </div>
                    <div className='w-full text-center text-[0.813rem] font-semibold uppercase [text-shadow:0px_4px_4px_rgba(0,0,0,.35)]'>
                        Lottery
                    </div>
                </div>
            </div>
            {/* Mobile */}
            <div className="lg:hidden grid grid-cols-12 gap-x-4 bg-[url('/public/casinobg.png')] bg-contain bg-fixed p-[0.938rem] border-b-[1px] border-[#7e97a7]">
                <div className='col-span-4'>
                    <div className='relative h-[115px]'>
                        <div className='clip w-[100%] !h-[115px]'></div>
                        <div className='clip2 !w-[89%] !h-[98px] !bottom-[100px] !left-[0vh]'>
                            <img title="" alt="" className="w-full h-full" src='/lottery.png'></img>
                        </div>
                    </div>
                    <div className='w-full text-center text-[0.813rem] font-semibold uppercase [text-shadow:0px_4px_4px_rgba(0,0,0,.35)]'>
                        Lottery
                    </div>
                </div>
            </div>
        </>
    );
}

export default Lottery;
