// import './App.css';
import '../../App.css'
import { useState } from 'react';
import { Modal, Input } from 'antd';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'

function RollingCommission() {
    const location = useLocation();
    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem('userdata'))

    const [isOpen, setIsOpen] = useState(true)
    const [passwordVisible, setPasswordVisible] = useState(false);
    const showAgeModal = () => {
        setIsOpen(true);
    };
    return (
        <div className='grid grid-cols-12'>
            {/* <div className='col-span-12 px-[6px] py-[5px] font-bold text-[0.875rem] rounded-t-[5px] h-[35px] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex flex-shrink-0 items-center justify-between'>
                <h5 className='text-[0.938rem] font-bold mb-0 leading-normal'>Rolling Commission</h5>
                <button type="button" onClick={() => setIsOpen(false)} >
                    <span className='text-[1.3125rem] font-bold'>×</span>
                </button>
            </div> */}
            <div className='col-span-12 rounded-b-[.3rem] !bg-[#ffffff] !text-[#23282c] p-4'>
                <div className='flex mb-[4px] border-[1px] border-[solid] border-[#000] !text-[#23282c]'>
                    <div className='w-[32%] lg:w-[23%] pl-[22px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        Fancy
                    </div>
                    <div className='w-[auto] pr-[25px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        <span> {userInfo?.rolling_commission_fancy}</span>
                    </div>
                </div>
                <div className='flex mb-[4px] border-[1px] border-[solid] border-[#000] !text-[#23282c]'>
                    <div className='w-[32%] lg:w-[23%] pl-[22px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        Matka
                    </div>
                    <div className='w-[auto] pr-[25px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        <span>{userInfo?.rolling_commission_matka}</span>
                    </div>
                </div>
                <div className='flex mb-[4px] border-[1px] border-[solid] border-[#000] !text-[#23282c]'>
                    <div className='w-[32%] lg:w-[23%] pl-[22px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        Lottery
                    </div>
                    <div className='w-[auto] pr-[25px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        <span>0</span>
                    </div>
                </div>
                <div className='flex mb-[4px] border-[1px] border-[solid] border-[#000] !text-[#23282c]'>
                    <div className='w-[32%] lg:w-[23%] pl-[22px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        Casino
                    </div>
                    <div className='w-[auto] pr-[25px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        <span>{userInfo?.rolling_commission_casino}</span>
                    </div>
                </div>
                <div className='flex mb-[4px] border-[1px] border-[solid] border-[#000] !text-[#23282c]'>
                    <div className='w-[32%] lg:w-[23%] pl-[22px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        Binary
                    </div>
                    <div className='w-[auto] pr-[25px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        <span>{userInfo?.rolling_commission_binary}</span>
                    </div>
                </div>
                <div className='flex mb-[4px] border-[1px] border-[solid] border-[#000] !text-[#23282c]'>
                    <div className='w-[32%] lg:w-[23%] pl-[22px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        Virtual
                    </div>
                    <div className='w-[auto] pr-[25px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        <span>{userInfo?.rolling_commission_virtualsport}</span>
                    </div>
                </div>
                <div className='flex mb-[4px] border-[1px] border-[solid] border-[#000] !text-[#23282c]'>
                    <div className='w-[32%] lg:w-[23%] pl-[22px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        Sportbook
                    </div>
                    <div className='w-[auto] pr-[25px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        <span>{userInfo?.rolling_commission_sportbook}</span>
                    </div>
                </div>
                <div className='flex mb-[4px] border-[1px] border-[solid] border-[#000] !text-[#23282c]'>
                    <div className='w-[32%] lg:w-[23%] pl-[22px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        Bookmaker
                    </div>
                    <div className='w-[auto] pr-[25px] py-[7px] mb-0 [font-size:inherit] leading-normal font-bold !text-[#23282c]'>
                        <span>{userInfo?.rolling_commission_bookmaker}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default RollingCommission;
