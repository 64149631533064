import '../../App.css'
import { useState } from 'react';
import { FaEllipsisVertical } from "react-icons/fa6";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";
function LeftSidebar() {
    const location = useLocation();
    const navigate = useNavigate();
    const navItems = [
        {
            'title': 'Cricket',
            'url': '/allsport-highlight/4'
        },
        {
            'title': 'Tennis',
            'url': '/allsport-highlight/2'
        },
        {
            'title': 'Casino',
            'url': '/livecasino'
        },
        {
            'title': 'Soccer',
            'url': '/allsport-highlight/1'
        },
        {
            'title': 'Horse Racing',
            'url': '/allsport-highlight/7'
        },
        {
            'title': 'Greyhound Racing',
            'url': '/allsport-highlight'
        },
        {
            'title': 'Basketball',
            'url': '/allsport-highlight'
        },
        {
            'title': 'Politics',
            'url': '/allsport-highlight'
        },
        {
            'title': 'Lottery',
            'url': '/allsport-highlight'
        },

    ]
    const cricketNav = [
        {
            'title': 'Indian Premier League ',
            'items': [
                ' Indian Premier League ',
                ' Lucknow Super Giants v Mumbai Indians ',
                ' Chennai Super Kings v Punjab Kings ',
                ' Sunrisers Hyderabad v Rajasthan Royals '
            ]
        },
        {
            'title': ' Womens International Twenty20 Matches  ',
            'items': [
                'Bangladesh Women v India Women',
                ' Pakistan Women v West Indies Women ',
            ]
        },
        {
            'title': ' Rachael Heyhoe Flint Trophy ',
            'items': [
                ' Northern Diamonds v The Blaze ',
                ' Western Storm v South East Stars ',
                ' Central Sparks v Sunrisers ',
            ]
        },
    ]
    return (
        <div className='bg-[linear-gradient(-180deg,_#FFFFFF_0%,_#FFFFFF_100%)] fixed w-full max-w-[17.4vw] hover:overflow-auto' style={{ height: 'calc(100vh - 6.25rem)' }}>
            <div className='grid grid-cols-12 '>
                <div className='flex justify-between col-span-12 items-center px-[7px] bg-[linear-gradient(-180deg,_#315195_0%,_#14213D_100%)]'>
                    <div className='flex text-white text-[20px]'>
                        <FaEllipsisVertical />
                    </div>
                    <div className='flex text-white text-xs leading-[25px] font-extrabold'>
                        Sports
                    </div>
                </div>
                <div className='flex col-span-12'>
                    {location.pathname === '/allsport-highlight/4' ?
                        <ul className=' w-full'>
                            {cricketNav.map((item, index) => (
                                <details key={index} className="group">
                                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                        <li className='flex items-center justify-between  cursor-pointer w-full text-[0.813rem] text-[#223869] hover:text-[#83AE17] hover:bg-[#E6EFD1] ' style={{ padding: '0.3rem 0.5rem .3rem 1rem', borderBottom: '1px solid #d2d6e2' }}
                                        >
                                            {item.title}
                                            <span className='dropdown-li'>
                                                <svg className="transition group-open:rotate-[90deg]" fill='#83AE17' width="7" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 6.32L1.68 0L0 1.67L4.65 6.32L0 10.97L1.68 12.64L8 6.32Z" />
                                                </svg>
                                            </span>
                                        </li>
                                    </summary>
                                    {item.items.map((item, index) => (
                                        <li key={index} className='inline-block items-center justify-between cursor-pointer w-full text-[0.813rem] text-[#223869]  hover:bg-[#E6EFD1] ' style={{ padding: '0.3rem 0.5rem .3rem 1rem', borderBottom: '1px solid #d2d6e2' }} >
                                            <p className='flex items-center gap-2'><FaArrowRight /> {item} </p>
                                        </li>
                                    ))}
                                </details>
                            ))}

                        </ul>
                        :
                        <ul className=' w-full'>
                            {
                                navItems.map((items, index) => (
                                    <li key={index} className='flex items-center justify-between  cursor-pointer w-full text-[0.813rem] text-[#223869] hover:text-[#83AE17] hover:bg-[#E6EFD1] ' style={{ padding: '0.3rem 0.5rem .3rem 1rem', borderBottom: '1px solid #d2d6e2' }} onClick={() => navigate(items.url)}>
                                        {items.title}
                                        <span className='dropdown-li'>
                                            <svg className="transition " fill='#83AE17' width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 6.32L1.68 0L0 1.67L4.65 6.32L0 10.97L1.68 12.64L8 6.32Z" />

                                            </svg>
                                        </span>
                                    </li>
                                ))
                            }
                        </ul>
                    }
                </div>
            </div>
        </div>
    )
}


export default LeftSidebar;
