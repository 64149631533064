// import './App.css';
import '../../App.css'
import { useState, useEffect } from 'react';
import { Modal, Input, DatePicker, Table, Select, Pagination } from 'antd';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import qs from 'qs';
import dayjs from 'dayjs';
import Appconfig from '../../config/config'
import axios from 'axios';



function PasswordHistory() {
    const location = useLocation();
    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem('userdata'))
    const [formData, setFormData] = useState({
        "user_id": userInfo && userInfo?._id,
    })

    const [filteredData, setFilteredData] = useState([]);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(false);

    let locale = {
        emptyText: 'No data!',
    };

    const columns = [
        {
            title: 'Date/Time',
            dataIndex: 'date-time',
            sorter: true,
            render: (_, data) => (
                <div>{new Date(data.createdAt).toLocaleString()}</div>
            )
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            sorter: true,
            render: (_, data) => (
                <div>{data.remark}</div>
            )
        },
    ];

    const handlePageSizeChange = (e) => {
        setPageSize(e);
        setCurrent(1); // Reset to the first page after changing page size
    };
    const handleFirst = () => setCurrent(1);
    const handleLast = () => setCurrent(Math.ceil(filteredData.length / pageSize));

    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedData = filteredData.slice(startIndex, endIndex);

    useEffect(() => {
        getPasswordHistory();

    }, [])

    const getPasswordHistory = async (e) => {

        var data = JSON.stringify(formData);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}users/auraPasswordHistory`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                try {
                    console.log(response.data);
                    setFilteredData(response.data);

                } catch (e) {
                    // postErrorToslack(e.message);
                }
            })
            .catch(function (error) {
                console.log(error);
                // postErrorToslack(error.message);
            });
    }

    return (
        <>
            <div className='hidden lg:block mt-[6px] !mb-4 text-[0.75rem] relative flex flex-col min-w-[0] [word-wrap:break-word]   rounded-[.25rem]'>
                <div className='grid grid-cols-12 mt-[22.5px] mb-[40px]'>
                    <div className='col-span-12'>
                        <div className=' flex-[0_0_auto] w-full max-w-full'>
                            <div className='border-r-[1px] border-r-[#c8ced3] border-l-[1px] border-l-[#c8ced3] mb-[24px] border-t-[2px] border-t-[black] relative flex flex-col min-w-[0] text-[#212529] [word-wrap:break-word] bg-[#fff] bg-clip-border border-[1px] border-[solid] border-[#0000002d] rounded-[.375rem]'>
                                <div className='pt-[5px] px-[6px] pb-[6px] font-bold text-[14px] !bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] mb-0 border-b-[1px_solid_#0000002d] !text-[#ffffff] [word-wrap:break-word]'>
                                    <span className='relative top-px font-bold text-[0.875rem] !text-[#ffffff]'>Password Change History</span>
                                </div>
                                {/* <div className='p-[1.25rem] flex-auto '>
                                    <Table
                                        locale={locale}
                                        columns={columns}
                                        className='profit-loss'
                                        bordered
                                        dataSource={data}
                                        pagination={tableParams.pagination}
                                        loading={loading}
                                        onChange={handleTableChange}
                                    />
                                </div> */}
                                <div className='p-[1.25rem] flex-auto '>
                                    <Table
                                        title={() => (
                                            <div className='grid grid-cols-12'>
                                                <div className='col-span-12 lg:col-span-6 flex items-center justify-center lg:justify-start mb-[.9rem] lg:mb-[0px]'>
                                                    <div className='text-[#333] lg:m-[.5rem]'>
                                                        <label>
                                                            Show
                                                            <Select defaultValue={10} onChange={handlePageSizeChange} className='pagesize-select mx-[5px] w-[80px]'>
                                                                <Select.Option value={10}>10</Select.Option>
                                                                <Select.Option value={25}>25</Select.Option>
                                                                <Select.Option value={50}>50</Select.Option>
                                                                <Select.Option value={100}>100</Select.Option>
                                                            </Select>
                                                            entries
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* <div className='col-span-12 lg:col-span-6 flex items-center justify-center lg:justify-end'>
                                                    <div className='text-[#333] lg:m-[.5rem]'>
                                                        <label>
                                                            Search:
                                                            <Input
                                                                // onChange={e => handleSearch(e.target.value, 'name')}
                                                                style={{ width: 155, marginLeft: '5px' }}
                                                            />
                                                        </label>
                                                    </div>
                                                </div> */}
                                            </div>
                                        )}
                                        locale={locale}
                                        columns={columns}
                                        className='profit-loss'
                                        bordered
                                        dataSource={paginatedData}
                                        pagination={false} // Disable default pagination
                                    />
                                    <div className='grid grid-cols-12 items-center pt-[1.5rem] lg:pt-[.75rem] text-[#333]'>
                                        <div className='col-span-12 lg:col-span-6 text-[#333] text-[0.813rem]'>
                                            Showing {startIndex + 1} to {endIndex} of {filteredData.length} entries
                                        </div>
                                        <div className='col-span-12 lg:col-span-6 flex items-center justify-end  pt-[1rem] lg:pt-[0px] lg:mt-[0rem]'>
                                            <button className='text-[0.813rem] cursor-default text-[#666] border-[1px] border-[solid] border-[transparent] bg-transparent [box-shadow:none] box-border inline-block min-w-[1.5em] px-[1em] py-[.5em] ml-[2px] text-center no-underline rounded-[2px] cursor-pointer' type='button' onClick={handleFirst} style={{ marginRight: 8 }}>First</button>
                                            <Pagination
                                                current={current}
                                                pageSize={pageSize}
                                                total={filteredData.length}
                                                onChange={(page) => setCurrent(page)}
                                                itemRender={(page, type, originalElement) => {
                                                    if (type === 'prev') {
                                                        return <button type='button' className='!cursor-pointer'>Previous</button>;
                                                    }
                                                    if (type === 'page') {
                                                        return null; // Hide page numbers
                                                    }
                                                    if (type === 'next') {
                                                        return <button type='button' className='!cursor-pointer'>Next</button>;
                                                    }
                                                    return originalElement;
                                                }}
                                                showSizeChanger={false} // Disable default page size changer
                                            />
                                            <button className='text-[0.813rem] cursor-default text-[#666] border-[1px] border-[solid] border-[transparent] bg-transparent [box-shadow:none] box-border inline-block min-w-[1.5em] px-[1em] py-[.5em] ml-[2px] text-center no-underline rounded-[2px]  cursor-pointer' type='button' onClick={handleLast} style={{ marginLeft: 8 }}>Last</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='lg:hidden relative top-[56px]  text-[0.75rem]  flex flex-col min-w-[0] [word-wrap:break-word] rounded-[.25rem]'>
                <div className='grid grid-cols-12 mt-[22.5px] mb-[40px]'>
                    <div className='col-span-12'>
                        <div className=' flex-[0_0_auto] w-full max-w-full'>
                            <div className='border-r-[1px] border-r-[#c8ced3] border-l-[1px] border-l-[#c8ced3] mb-[24px] border-t-[2px] border-t-[black] relative flex flex-col min-w-[0] text-[#212529] [word-wrap:break-word] bg-[#fff] bg-clip-border border-[1px] border-[solid] border-[#0000002d] rounded-[.375rem]'>
                                <div className='pt-[5px] px-[6px] pb-[6px] font-bold text-[14px] !bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] mb-0 border-b-[1px_solid_#0000002d] !text-[#ffffff] [word-wrap:break-word]'>
                                    <span className='relative top-px font-bold text-[0.875rem] !text-[#ffffff]'>Password Change History</span>
                                </div>
                                {/* <div className='p-[1.25rem] flex-auto '>
                                    <Table
                                        locale={locale}
                                        columns={columns}
                                        bordered
                                        className='profit-loss'
                                        dataSource={data}
                                        pagination={tableParams.pagination}
                                        loading={loading}
                                        onChange={handleTableChange}
                                    />
                                </div> */}
                                <div className='p-[1.25rem] flex-auto '>
                                    <Table
                                        title={() => (
                                            <div className='grid grid-cols-12'>
                                                <div className='col-span-12 lg:col-span-6 flex items-center justify-center lg:justify-start  lg:mb-[0px]'>
                                                    <div className='text-[#333] lg:m-[.5rem]'>
                                                        <label>
                                                            Show
                                                            <Select defaultValue={10} onChange={handlePageSizeChange} className='pagesize-select mx-[10px] w-[80px]'>
                                                                <Select.Option value={10}>10</Select.Option>
                                                                <Select.Option value={25}>25</Select.Option>
                                                                <Select.Option value={50}>50</Select.Option>
                                                                <Select.Option value={100}>100</Select.Option>
                                                            </Select>
                                                            entries
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* <div className='col-span-12 lg:col-span-6 flex items-center justify-center lg:justify-end'>
                                                    <div className='text-[#333] lg:m-[.5rem]'>
                                                        <label>
                                                            Search:
                                                            <Input
                                                                // onChange={e => handleSearch(e.target.value, 'name')}
                                                                style={{ width: 155, marginLeft: '5px' }}
                                                            />
                                                        </label>
                                                    </div>
                                                </div> */}
                                            </div>
                                        )}
                                        locale={locale}
                                        columns={columns}
                                        className='profit-loss'
                                        bordered
                                        dataSource={paginatedData}
                                        pagination={false} // Disable default pagination
                                    />
                                    <div className='grid grid-cols-12 items-center pt-[.5rem] lg:pt-[.75rem] text-[#333]'>
                                        <div className='col-span-12 text-center lg:col-span-6 text-[#333] text-[0.813rem]'>
                                            Showing {startIndex + 1} to {endIndex} of {filteredData.length} entries
                                        </div>
                                        <div className='col-span-12 lg:col-span-6 flex items-center justify-center  pt-[1rem] lg:pt-[0px] lg:mt-[0rem]'>
                                            <button className='text-[0.813rem] cursor-default text-[#666] border-[1px] border-[solid] border-[transparent] bg-transparent [box-shadow:none] box-border inline-block min-w-[1.5em] px-[1em] py-[.5em] ml-[2px] text-center no-underline rounded-[2px] cursor-pointer' type='button' onClick={handleFirst} style={{ marginRight: 8 }}>First</button>
                                            <Pagination
                                                current={current}
                                                pageSize={pageSize}
                                                total={filteredData.length}
                                                onChange={(page) => setCurrent(page)}
                                                itemRender={(page, type, originalElement) => {
                                                    if (type === 'prev') {
                                                        return <button type='button' className='!cursor-pointer'>Previous</button>;
                                                    }
                                                    if (type === 'page') {
                                                        return null; // Hide page numbers
                                                    }
                                                    if (type === 'next') {
                                                        return <button type='button' className='!cursor-pointer'>Next</button>;
                                                    }
                                                    return originalElement;
                                                }}
                                                showSizeChanger={false} // Disable default page size changer
                                            />
                                            <button className='text-[0.813rem] cursor-default text-[#666] border-[1px] border-[solid] border-[transparent] bg-transparent [box-shadow:none] box-border inline-block min-w-[1.5em] px-[1em] py-[.5em] ml-[2px] text-center no-underline rounded-[2px]  cursor-pointer' type='button' onClick={handleLast} style={{ marginLeft: 8 }}>Last</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default PasswordHistory;
