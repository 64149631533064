import '../../App.css'
import { Fragment, useEffect, useRef, useState, useContext } from 'react';
import { Modal } from 'antd';
import { LuClock9 } from "react-icons/lu";
import { SlScreenDesktop } from "react-icons/sl";
import { FaCircle } from "react-icons/fa";
import Winner from '../../components/winner';
import MatchOdds from '../../components/matchOdds';
import TiedMatch from '../../components/tiedMatch';
import Bookmaker from '../../components/bookmaker';
import WinTheMatch from '../../components/win-the-match';
import Fancy from '../../components/fancy';
import Sportsbook from '../../components/sportsbook';
import { json, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Appconfig from '../../config/config'
import { WebSocketContext } from '../../context/websocket';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
export default function ANDARBAHARA() {
    const navigate = useNavigate();
    const { casino_id } = useParams();
    const userInfo = JSON.parse(localStorage.getItem('userdata'))
    const [loadCasino, setLoadCasino] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        setTimeout(() => {
            setLoadCasino(false);
        }, 1000)
    })
    const [seconds, setSeconds] = useState(45);
    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setSeconds(45);
        }
    }, [seconds]);


    return (
        <>
            {loadCasino ?
                <div className='block absolute w-full left-0 top-0 h-screen overflow-hidden'>
                    <div className='fixed top-0 relative w-full h-screen flex justify-center items-center bg-[#000000] z-[99999]'>
                        <img src='/loader.gif' className='w-[350px]' />
                    </div>
                </div>
                :
                <div className='grid grid-cols-12 relative top-[56px] lg:top-0'>
                    <div className='col-span-12 relative flex'>
                        <video id="remoteVideo" width="100%" height="100%" autoplay="" playsinline="" muted="" style={{ backgroundColor: 'black', backgroundSize: 'contain' }}></video>
                        <div className='absolute bg-[rgba(0,_0,_0,_.4)] h-auto left-[0] content-[""] p-[3px]'>
                            <div className='flex flex-col'>
                                <div className="font-bold text-[#fff] !text-[10px]">RID: 9102906065327</div>
                                {/* <div className='flex flex-col'>
                                    <span className='leading-[9px] font-bold mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffffff]'>PLAYER A</span>
                                    <div className='flex'>
                                        <img className='w-[23px] h-auto mr-[3px]' src="/cards/0.png"></img>
                                        <img className='w-[23px] h-auto mr-[3px]' src="/cards/C9_.png"></img>
                                        <img className='w-[23px] h-auto mr-[3px]' src="/cards/D10_.png"></img>
                                    </div>
                                </div>
                                <div className='flex flex-col pt-[3px]'>
                                    <span className='leading-[9px] font-bold mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffffff]'>PLAYER B</span>
                                    <div className='flex'>
                                        <img className='w-[23px] h-auto mr-[3px]' src="/cards/SA_.png"></img>
                                        <img className='w-[23px] h-auto mr-[3px]' src="/cards/H3_.png"></img>
                                        <img className='w-[23px] h-auto mr-[3px]' src="/cards/DK_.png"></img>
                                    </div>
                                </div> */}
                                <div className='mt-[1rem] flex flex-wrap items-center'>
                                    <div className='flex-[0_0_auto] w-[8.33%]'>
                                        <div className='flex flex-wrap'>
                                            <div className='w-full'>
                                                <p className='mb-[0px] text-white'><b className='relative top-[-10px]'>A</b></p>
                                            </div>
                                        </div>
                                        <div className='flex flex-wrap'>
                                            <div className='w-full'>
                                                <p className='mb-[0px] text-white'><b className='relative top-[10px]'>B</b></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pl-[.25rem] flex-[0_0_auto] w-[16.66%]'>
                                        <img className='w-[23px] h-auto mr-[3px]' src="/cards/0.png"></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="winnerOverlay absolute h-auto bottom-2/4 ml-auto mr-auto p-[5px] w-full flex justify-center">
                            <div className="mini-result-new active border-[0] border-[solid] mb-0 text-[#000] bg-[linear-gradient(-180deg,_#fd8f3b_0%,_#fd3523_100%)] w-auto text-[14px] p-[6px] uppercase rounded-tl-[16px] rounded-br-[16px] rounded-tr-[2px] rounded-bl-[2px] min-h-[20px] [transition:all_.5s_cubic-bezier(.168,-.885,.3,2)_.5s] scale-0 scale-100">
                                <span > PLAYER B win </span>
                            </div>
                            <span className='absolute' >
                                <canvas width="100vw" height="100vh">
                                </canvas>
                            </span>
                        </div>


                        <div className="timer-overlay  ">
                            <div className='setting-btn text-white text-[1rem]'>
                                <i className="fa fa-cog fa-lg" style={{ color: '#ff8100' }}></i>
                            </div>
                            <div className="timer-group">
                                <div className="timer minute">
                                    <div className="hand">
                                        <span className="">
                                        </span>
                                    </div>
                                    <div className="hand">
                                        <span className="">
                                        </span>
                                    </div>
                                </div>
                                <div className="face">
                                    <p id="lazy" className="">{seconds}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-12 h-[calc(101vh-40vh)] lg:h-auto overflow-auto'>
                        <div className='grid grid-cols-12'>
                            <div className='col-span-12 w-[100%] py-[5px] flex  bg-[#ddd] text-[#000] text-[.75rem]'>
                                <div className='grid grid-cols-12 w-full'>
                                    <div className='col-span-12 lg:col-span-6 flex justify-center '>
                                        <span className='w-[10%] text-center pt-[15px] pb-[5px] text-[20px]'>A</span>
                                        <div className='w-[20%] ml-[10px]'>
                                            <div className='flex flex-col text-center py-[5px] border-[3px] border-[#ff0] rounded-[8px] cursor-pointer price suspendedandar relative'>
                                                <span> SIDE BET A </span>
                                                <span className='mt-[.25rem]'> 12 </span>
                                            </div>
                                            <div className='book text-center mt-[5px] text-[#000]'>
                                                <span className='extra-pf'>
                                                    <div className='profitLoss'>
                                                        <span className="text-center text-[#000]"> 0</span>
                                                    </div>

                                                </span>

                                            </div>
                                        </div>

                                        <div className='first-bet w-[25%] mx-[10px]'>
                                            <div className='flex flex-col text-center py-[5px] border-[3px] border-[#ff0] rounded-[8px] cursor-pointer price suspendedandar relative bg-[#086cb8] text-[#fff]'>
                                                <span>  1st BET A  </span>
                                                <span className='mt-[.25rem]'> 12 </span>
                                            </div>
                                            <div className='book text-center mt-[5px] text-[#000]'>
                                                <span className='extra-pf'>
                                                    <div className='profitLoss'>
                                                        <span className="text-center text-[#000]"> 0</span>
                                                    </div>

                                                </span>

                                            </div>
                                        </div>
                                        <div className='first-bet w-[25%] mx-[10px]'>
                                            <div className='flex flex-col text-center py-[5px] border-[3px] border-[#ff0] rounded-[8px] cursor-pointer price suspendedandar relative bg-[#086cb8] text-[#fff]'>
                                                <span>  1st BET A  </span>
                                                <span className='mt-[.25rem]'> 12 </span>
                                            </div>
                                            <div className='book text-center mt-[5px] text-[#000]'>
                                                <span className='extra-pf'>
                                                    <div className='profitLoss'>
                                                        <span className="text-center text-[#000]"> 0</span>
                                                    </div>

                                                </span>

                                            </div>
                                        </div>
                                        <span className='w-[10%] text-center pt-[15px] pb-[5px] text-[20px]'>A</span>

                                    </div>

                                    <div className='col-span-12 lg:col-span-6 flex justify-center  ml-[10px]'>
                                        <span className='w-[10%] text-center pt-[15px] pb-[5px] text-[20px]'>B</span>
                                        <div className='w-[20%] ml-[10px]'>
                                            <div className='flex flex-col text-center py-[5px] border-[3px] border-[#ff0] rounded-[8px] cursor-pointer price suspendedandar relative'>
                                                <span> SIDE BET B </span>
                                                <span className='mt-[.25rem]'> 12 </span>
                                            </div>
                                            <div className='book text-center mt-[5px] text-[#000]'>
                                                <span className='extra-pf'>
                                                    <div className='profitLoss'>
                                                        <span className="text-center text-[#000]"> 0</span>
                                                    </div>

                                                </span>

                                            </div>
                                        </div>

                                        <div className='first-bet w-[25%] mx-[10px]'>
                                            <div className='flex flex-col text-center py-[5px] border-[3px] border-[#ff0] rounded-[8px] cursor-pointer price suspendedandar relative bg-[#086cb8] text-[#fff]'>
                                                <span>  1st BET B  </span>
                                                <span className='mt-[.25rem]'> 12 </span>
                                            </div>
                                            <div className='book text-center mt-[5px] text-[#000]'>
                                                <span className='extra-pf'>
                                                    <div className='profitLoss'>
                                                        <span className="text-center text-[#000]"> 0</span>
                                                    </div>

                                                </span>

                                            </div>
                                        </div>
                                        <div className='first-bet w-[25%] mx-[10px]'>
                                            <div className='flex flex-col text-center py-[5px] border-[3px] border-[#ff0] rounded-[8px] cursor-pointer price suspendedandar relative bg-[#086cb8] text-[#fff]'>
                                                <span>  1st BET B  </span>
                                                <span className='mt-[.25rem]'> 12 </span>
                                            </div>
                                            <div className='book text-center mt-[5px] text-[#000]'>
                                                <span className='extra-pf'>
                                                    <div className='profitLoss'>
                                                        <span className="text-center text-[#000]"> 0</span>
                                                    </div>

                                                </span>

                                            </div>
                                        </div>
                                        <span className='w-[10%] text-center pt-[15px] pb-[5px] text-[20px]'>B</span>

                                    </div>
                                </div>

                            </div>

                            <div className='col-span-12 w-[100%] flex justify-between text-[#000] text-[14px] mt-[.5rem]'>
                                <div className='grid grid-cols-12 gap-x-[.5rem] w-full'>
                                    <div className='col-span-12 lg:col-span-6 relative px-[15px] bg-[#ddd] text-[#000] p-[5px]'>
                                        <div className='grid grid-cols-12'>
                                            <div className='col-span-6 text-center px-[15px] relative'>
                                                <div className='text-[20px] h-[35px] '>
                                                    <b>ODD</b>
                                                </div>
                                                <div className='font-bold text-[18px] text-[#000] bg-[#72bbef] py-[2px] px-[10px] mt-[.25rem] relative price suspendedandar'>
                                                    <span className="odd text-[18px] font-bold text-[#000]"> 1.83 </span>
                                                </div>
                                                <div className='mt-[.25rem]'>
                                                    <span className='extra-pf'>
                                                        <div className='profitLoss'>
                                                            <span className="text-center text-[#000]"> 0</span>
                                                        </div>
                                                    </span>

                                                </div>
                                            </div>
                                            <div className='col-span-6 text-center px-[15px] relative'>
                                                <div className='text-[20px] h-[35px] '>
                                                    <b> EVEN </b>
                                                </div>
                                                <div className='font-bold text-[18px] text-[#000] bg-[#72bbef] py-[2px] px-[10px] mt-[.25rem] relative price suspendedandar'>
                                                    <span className="odd text-[18px] font-bold text-[#000]"> 1.83 </span>
                                                </div>
                                                <div className='mt-[.25rem]'>
                                                    <span className='extra-pf'>
                                                        <div className='profitLoss'>
                                                            <span className="text-center text-[#000]"> 0</span>
                                                        </div>
                                                    </span>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='col-span-12 lg:col-span-6 relative px-[15px] bg-[#ddd] text-[#000] p-[5px]'>
                                        <div className='grid grid-cols-12'>
                                            <div className='col-span-3 relative px-[15px] text-center'>
                                                <div className='text-[20px] h-[35px] flex justify-center'>
                                                    <img src='/andarbahar/SPADES.png' className='h-[35px] align-middle' />
                                                </div>
                                                <div className='font-black text-[18px] text-[#000] bg-[#72bbef] py-[2px] px-[10px] mt-[.25rem] relative price suspendedandar'>
                                                    <span className="odd"> 3.7 </span>
                                                </div>
                                                <div className='mt-[.25rem]'>
                                                    <span className='extra-pf'>
                                                        <div className='profitLoss'>
                                                            <span className="text-center text-[#000]"> 0</span>
                                                        </div>
                                                    </span>

                                                </div>
                                            </div>
                                            <div className='col-span-3 relative px-[15px] text-center'>
                                                <div className='text-[20px] h-[35px] flex justify-center'>
                                                    <img src='/andarbahar/HEARTS.png' className='h-[35px] align-middle' />
                                                </div>
                                                <div className='font-black text-[18px] text-[#000] bg-[#72bbef] py-[2px] px-[10px] mt-[.25rem] relative price suspendedandar'>
                                                    <span className="odd"> 3.7 </span>
                                                </div>
                                                <div className='mt-[.25rem]'>
                                                    <span className='extra-pf'>
                                                        <div className='profitLoss'>
                                                            <span className="text-center text-[#000]"> 0</span>
                                                        </div>
                                                    </span>

                                                </div>
                                            </div>
                                            <div className='col-span-3 relative px-[15px] text-center'>
                                                <div className='text-[20px] h-[35px] flex justify-center'>
                                                    <img src='/andarbahar/DIAMONDS.png' className='h-[35px] align-middle' />
                                                </div>
                                                <div className='font-black text-[18px] text-[#000] bg-[#72bbef] py-[2px] px-[10px] mt-[.25rem] relative price suspendedandar'>
                                                    <span className="odd"> 3.7 </span>
                                                </div>
                                                <div className='mt-[.25rem]'>
                                                    <span className='extra-pf'>
                                                        <div className='profitLoss'>
                                                            <span className="text-center text-[#000]"> 0</span>
                                                        </div>
                                                    </span>

                                                </div>
                                            </div>
                                            <div className='col-span-3 relative px-[15px] text-center'>
                                                <div className='text-[20px] h-[35px] flex justify-center'>
                                                    <img src='/andarbahar/CLUBS.png' className='h-[35px] align-middle' />
                                                </div>
                                                <div className='font-black text-[18px] text-[#000] bg-[#72bbef] py-[2px] px-[10px] mt-[.25rem] relative price suspendedandar'>
                                                    <span className="odd"> 3.7 </span>
                                                </div>
                                                <div className='mt-[.25rem]'>
                                                    <span className='extra-pf'>
                                                        <div className='profitLoss'>
                                                            <span className="text-center text-[#000]"> 0</span>
                                                        </div>
                                                    </span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='col-span-12 w-[100%] bg-[#ddd] text-[#000] p-[5px] mt-[.5rem]'>
                                <div className='w-full relative px-[15px] text-center'>
                                    <span className='text-[18px]'>12</span>
                                </div>
                                <div className='w-full relative px-[15px] text-center'>
                                    <div className='mr-[5px] inline-block'>
                                        <div className='suspended-card relative'>
                                            <img src='/t10cards/A.jpeg' className='w-[34px]' />
                                        </div>
                                        <div className='text-center mb-[.5rem]'>
                                            <span className='extra-pf'>
                                                <div className='profitLoss'>
                                                    <span className="text-center text-[#000]"> 0</span>
                                                </div>

                                            </span>
                                        </div>
                                    </div>
                                    <div className='mr-[5px] inline-block'>
                                        <div className='suspended-card relative'>
                                            <img src='/t10cards/2.jpeg' className='w-[34px]' />
                                        </div>
                                        <div className='text-center mb-[.5rem]'>
                                            <span className='extra-pf'>
                                                <div className='profitLoss'>
                                                    <span className="text-center text-[#000]"> 0</span>
                                                </div>

                                            </span>
                                        </div>
                                    </div>
                                    <div className='mr-[5px] inline-block'>
                                        <div className='suspended-card relative'>
                                            <img src='/t10cards/3.jpeg' className='w-[34px]' />
                                        </div>
                                        <div className='text-center mb-[.5rem]'>
                                            <span className='extra-pf'>
                                                <div className='profitLoss'>
                                                    <span className="text-center text-[#000]"> 0</span>
                                                </div>

                                            </span>
                                        </div>
                                    </div>
                                    <div className='mr-[5px] inline-block'>
                                        <div className='suspended-card relative'>
                                            <img src='/t10cards/4.jpeg' className='w-[34px]' />
                                        </div>
                                        <div className='text-center mb-[.5rem]'>
                                            <span className='extra-pf'>
                                                <div className='profitLoss'>
                                                    <span className="text-center text-[#000]"> 0</span>
                                                </div>

                                            </span>
                                        </div>
                                    </div>
                                    <div className='mr-[5px] inline-block'>
                                        <div className='suspended-card relative'>
                                            <img src='/t10cards/5.jpeg' className='w-[34px]' />
                                        </div>
                                        <div className='text-center mb-[.5rem]'>
                                            <span className='extra-pf'>
                                                <div className='profitLoss'>
                                                    <span className="text-center text-[#000]"> 0</span>
                                                </div>

                                            </span>
                                        </div>
                                    </div>
                                    <div className='mr-[5px] inline-block'>
                                        <div className='suspended-card relative'>
                                            <img src='/t10cards/6.jpeg' className='w-[34px]' />
                                        </div>
                                        <div className='text-center mb-[.5rem]'>
                                            <span className='extra-pf'>
                                                <div className='profitLoss'>
                                                    <span className="text-center text-[#000]"> 0</span>
                                                </div>

                                            </span>
                                        </div>
                                    </div>
                                    <div className='mr-[5px] inline-block'>
                                        <div className='suspended-card relative'>
                                            <img src='/t10cards/7.jpeg' className='w-[34px]' />
                                        </div>
                                        <div className='text-center mb-[.5rem]'>
                                            <span className='extra-pf'>
                                                <div className='profitLoss'>
                                                    <span className="text-center text-[#000]"> 0</span>
                                                </div>

                                            </span>
                                        </div>
                                    </div>
                                    <div className='mr-[5px] inline-block'>
                                        <div className='suspended-card relative'>
                                            <img src='/t10cards/8.jpeg' className='w-[34px]' />
                                        </div>
                                        <div className='text-center mb-[.5rem]'>
                                            <span className='extra-pf'>
                                                <div className='profitLoss'>
                                                    <span className="text-center text-[#000]"> 0</span>
                                                </div>

                                            </span>
                                        </div>
                                    </div>
                                    <div className='mr-[5px] inline-block'>
                                        <div className='suspended-card relative'>
                                            <img src='/t10cards/9.jpeg' className='w-[34px]' />
                                        </div>
                                        <div className='text-center mb-[.5rem]'>
                                            <span className='extra-pf'>
                                                <div className='profitLoss'>
                                                    <span className="text-center text-[#000]"> 0</span>
                                                </div>

                                            </span>
                                        </div>
                                    </div>
                                    <div className='mr-[5px] inline-block'>
                                        <div className='suspended-card relative'>
                                            <img src='/t10cards/10.jpeg' className='w-[34px]' />
                                        </div>
                                        <div className='text-center mb-[.5rem]'>
                                            <span className='extra-pf'>
                                                <div className='profitLoss'>
                                                    <span className="text-center text-[#000]"> 0</span>
                                                </div>

                                            </span>
                                        </div>
                                    </div>
                                    <div className='mr-[5px] inline-block'>
                                        <div className='suspended-card relative'>
                                            <img src='/t10cards/J.jpeg' className='w-[34px]' />
                                        </div>
                                        <div className='text-center mb-[.5rem]'>
                                            <span className='extra-pf'>
                                                <div className='profitLoss'>
                                                    <span className="text-center text-[#000]"> 0</span>
                                                </div>

                                            </span>
                                        </div>
                                    </div>
                                    <div className='mr-[5px] inline-block'>
                                        <div className='suspended-card relative'>
                                            <img src='/t10cards/Q.jpeg' className='w-[34px]' />
                                        </div>
                                        <div className='text-center mb-[.5rem]'>
                                            <span className='extra-pf'>
                                                <div className='profitLoss'>
                                                    <span className="text-center text-[#000]"> 0</span>
                                                </div>

                                            </span>
                                        </div>
                                    </div>
                                    <div className='mr-[5px] inline-block'>
                                        <div className='suspended-card relative'>
                                            <img src='/t10cards/K.jpeg' className='w-[34px]' />
                                        </div>
                                        <div className='text-center mb-[.5rem]'>
                                            <span className='extra-pf'>
                                                <div className='profitLoss'>
                                                    <span className="text-center text-[#000]"> 0</span>
                                                </div>

                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className='col-span-12 mt-[5px] bg-[#000] p-[5px] text-[#fff] inline-block overflow-x-auto mb-[100px] whitespace-nowrap'>
                                <h5 className='inline-block font-bold text-[1.07375rem] m-0'>Recent Result</h5>
                                <ul className='inline-block whitespace-nowrap'>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#72bbef] '>
                                        <button type='button' className='text-[#333]' onClick={showModal}>A</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]' >B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#72bbef] '>
                                        <button type='button' className='text-[#333]'>A</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                </ul>
                                {/* <Modal className='recent-result-modal relative top-1 lg:top-7 w-[500px]' open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel}>
                                    <div className='grid grid-cols-12'>
                                        <div className='col-span-12 flex rounded-t-[8px] items-center h-[45px] justify-between p-[10px] bg-[linear-gradient(-180deg,_#315195_0%,_#14213D_100%)] border-[1px] border-[solid] border-[#ffffff] text-[#ffffff]'>
                                            <span className='leading-normal text-[.938rem] font-bold'> 20-20 TEEN PATTI </span>
                                            <button type="button" className='text-[1.3rem] font-bold'>×</button>
                                        </div>
                                        <div className='col-span-12 max-h-[80vh] overflow-y-auto '>
                                            <div className='grid grid-cols-12 mb-[80px]'>
                                                <div className='col-span-12 p-[.5rem]'>
                                                    <h6 className='font-bold text-[1rem] text-end'>Round Id: 9102906141719 </h6>
                                                </div>
                                                <div className='col-span-6 text-center border-r-[1px] border-r-[#ddd] py-[5px] my-[10px] float-left'>
                                                    <h6 className='text-[1rem] font-bold' >Player A</h6>
                                                    <div className=''>
                                                        <div className='flex justify-center'>
                                                            <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/0.png"></img>
                                                            <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/C9_.png"></img>
                                                            <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/D10_.png"></img>
                                                        </div>
                                                        <button className='bg-[#28a745] py-[5px] px-[10px] text-[#fff] text-[20px] font-bold block my-[10px] mx-auto rounded-[.375rem]'>Winner</button>
                                                    </div>
                                                </div>
                                                <div className='col-span-6 text-center border-r-[1px] border-r-[#ddd] py-[5px] my-[10px] float-left'>
                                                    <h6 className='text-[1rem] font-bold' >Player B</h6>
                                                    <div className=''>
                                                        <div className='flex justify-center'>
                                                            <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/0.png"></img>
                                                            <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/C9_.png"></img>
                                                            <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/D10_.png"></img>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-span-12 border-y-[1px] border-[#ccc] py-[10px]'>
                                                    <h6 className='text-[.8rem] font-bold text-center'>WINNER</h6>
                                                    <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                        PLAYER A -
                                                        <i className="fa fa-trophy winner-icon"></i>
                                                    </div>
                                                    <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                        PLAYER B -
                                                        <i className="fa fa-trophy winner-icon loser-icon"></i>
                                                    </div>
                                                </div>
                                                <div className='col-span-12 border-y-[1px] border-[#ccc] py-[10px]'>
                                                    <h6 className='text-[.8rem] font-bold text-center'>PAIR ( DUBBLE ) 1:4</h6>
                                                    <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                        PLAYER A -
                                                        <i className="fa fa-trophy winner-icon"></i>
                                                    </div>
                                                    <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                        PLAYER B -
                                                        <i className="fa fa-trophy winner-icon loser-icon"></i>
                                                    </div>
                                                </div>
                                                <div className='col-span-12 border-y-[1px] border-[#ccc] py-[10px]'>
                                                    <h6 className='text-[.8rem] font-bold text-center'>FLUSH ( COLOR ) 1:8</h6>
                                                    <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                        PLAYER A -
                                                        <i className="fa fa-trophy winner-icon"></i>
                                                    </div>
                                                    <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                        PLAYER B -
                                                        <i className="fa fa-trophy winner-icon loser-icon"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal> */}
                            </div>
                        </div>
                    </div>

                </div >
            }
        </>
    )
}
